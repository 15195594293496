import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import { createTheme, Theme } from "@mui/material";
import GeneralSettings from "../models/GeneralSettings";
import { defaultTheme } from "../Theme";
import PreConsent from "../models/PreConsent";
import LoadingSpinner from "../components/LoadingSpinner";


interface D2CProps {
    basePath: string;
    consentUiBasePath: string;
    obApi: OpenBankingPlatformAPI;
    setTheme: (theme: Theme) => void;
    getPrincipalHeaderLogoSrc: (generalSettings: GeneralSettings) => Promise<string | null>;
    getAccreditationIconSrc: (generalSettings: GeneralSettings) => Promise<string | null>;
    getHeaderBgImageSrc: (generalSettings: GeneralSettings) => Promise<string | null>;
    loadPrincipalConfiguration: (principal_id?: number, skip_change?: boolean) => void;
}

interface D2CState {
    isLoading: boolean;
    apiError?: boolean;
    hasValidBrokerCode: boolean;
    loadedThemeOptions: Partial<Theme> | ((outerTheme: Theme) => Theme);
    principalLogoUrl: string;
    headerBgImageUrl?: string;
    loadedGeneralSettingsConfig?: GeneralSettings;
    loadedPreConsentConfig?: PreConsent;
    brokerCode: string;
}


const D2C = (props: D2CProps) => {
    const { code } = useParams<{ code: string }>();

    const navigate = useNavigate();
    const [state, setState] = useState<D2CState>({
        isLoading: true,
        apiError: false,
        hasValidBrokerCode: false,
        loadedThemeOptions: createTheme(defaultTheme),
        brokerCode: code || "",
        principalLogoUrl: "",
        headerBgImageUrl: "",
    });

    //eslint-disable-next-line
    const getSession = useCallback(props.obApi.getConsentSession, []);

    useEffect(() => {
        (async () => {
            if (!state.brokerCode) return;

            try {
                // getsession will save the account_principal_id into the backend session.
                await getSession(state.brokerCode);
                await props.loadPrincipalConfiguration(0);

                setState(prev => ({
                    ...prev,
                    isLoading: false,
                    hasValidBrokerCode: true
                }));

                // await loadConfigurations(loadedConfigurations);
            } catch (error: unknown) {
                const response = (error as { response?: { status?: number; data?: { brokerCode: string } } })?.response;
                setState(prev => ({
                    ...prev,
                    isLoading: false,
                    apiError: true,
                    ...(response?.status === 419 && { brokerCode: response?.data?.brokerCode })
                }));

                consentRedirectByErrorResponseStatus(navigate, response, props.basePath);
            }
        })();
    //eslint-disable-next-line
    }, [getSession, navigate, props.basePath, state.brokerCode]);

    useEffect(() => {
        if (state.hasValidBrokerCode) {
            navigate(`${props.consentUiBasePath}/customer-details`);
        }
    //eslint-disable-next-line
    }, [state.hasValidBrokerCode, navigate, props.basePath]);

    // Render CustomerDetailsInputs or navigate to NotFound based on conditions
    return state.isLoading ? (
        <LoadingSpinner position={"fixed"} overlay />
    ) : state.hasValidBrokerCode ? (
        // should not be here as <navigate> has redirect the page
        null
    ) : (
        <Navigate to={`${props.basePath}/notfound`} replace />
    );

};

export default D2C;
