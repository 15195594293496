import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoIcon from "@mui/icons-material/Info";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Box, Button, FormControl, Grid, IconButton, Link, MenuItem, Select, SelectChangeEvent, TextField, Typography, useTheme } from "@mui/material";

import ButtonBlock from "../../components/ButtonBlock";
import LoadingSpinner from "../../components/LoadingSpinner";
import {isClientManager, isStandardUser, isAdmin, isAdminOrClientManager} from "../../helpers/AuthUserHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import Popup from "../../popups/Popup";
import Account from "../models/Account";
import Configuration from "../models/Configuration";
import DashboardConfigurationCard from "./DashboardConfigurationCard";
import LightTooltip from "./LightTooltip";
import AccountType from "../models/AccountType";

interface AdminDashboardProps
{
    adminObApi: OpenBankingPlatformAPIInterface;
    accountId?: number;
    configurations: Array<Configuration>;
    isLoading: boolean;
    basePath: string;
    currentUser?: CurrentUser,
}

interface AdminDashboardState
{
    backgroundOperationInProgress: boolean;
    uploadedConfiguration?: string;
    uploadedConfigurationFileName?: string;
    //configurations: Array<Configuration>;
}


const ConfigurationDashboard = (props: AdminDashboardProps) => {
    const [open, setOpen] = React.useState(false);

    const openPopup = () => {
        setState({
            ...state,
            uploadedConfiguration: "",
            uploadedConfigurationFileName: "",
        });
        setOpen(true);
    };

    const closePopup = () => {
        setOpen(false);
    };

    const importConfigPopup = () => {
        //const providerPopUpData = modalPopupsData[0];
        openPopup();
    }

    const cdrFileUploadInput = useRef() as MutableRefObject<HTMLInputElement>;

    const [accountDetails, setAccount] = useState<Account>({
        status: 'ACTIVE',
        account_type: 1
    });


    const [accountTypeSelectionShouldDisable, setAccountTypeSelectionShouldDisable] = useState(false);
    useEffect(() => {
        const configHasAccountTypeThree = props.configurations.some(config => config.account_type === 3);
        setAccountTypeSelectionShouldDisable(accountDetails.account_type === 3 && configHasAccountTypeThree);
    }, [accountDetails, props.configurations]);


    const [accountTypes, setAccountTypes] = useState<AccountType[]>([]);
    useEffect(() => {
        const fetchAccountTypes = async () => {
            const data = await props.adminObApi.getAccountTypes();
            setAccountTypes(data);
        };
        fetchAccountTypes();
    //eslint-disable-next-line
    }, []);

    const [error, setError] = useState('');

    const [state, setState] = useState<AdminDashboardState>({
        backgroundOperationInProgress: false,
    })

    const handleUpload = (event:any) => {
        const fileReader = new FileReader();
        fileReader.onload = (event:any) => {
            setState({
                ...state,
                uploadedConfiguration: event.target.result,
            });
        };
        fileReader.readAsText(event.target.files[0], "UTF-8");
        state.uploadedConfigurationFileName = event.target.files[0].name;
    }

    // eslint-disable-next-line
    const createNewConfiguration = useCallback(props.adminObApi.createNewConfiguration, []);
    const handleImport = () => {
        if (state.uploadedConfiguration) {
            let configObj:Configuration = JSON.parse(state.uploadedConfiguration);
            if (configObj.hasOwnProperty('id') && configObj.hasOwnProperty('name') && configObj.hasOwnProperty('description')) {
                // Don't import general settings which are either image files, or environment specific settings such as endpoints
                configObj.principal_id = 0;
                configObj.generalSettings.principalUuid = '';
                configObj.generalSettings.referenceCode = '';
                configObj.generalSettings.statementDeliveryEmail = '';
                configObj.generalSettings.statementDeliveryEndpoint = '';
                configObj.generalSettings.consentExpiryNotificationEndpoint = '';
                configObj.generalSettings.callbackURL = '';
                configObj.generalSettings.redirectURL = '';
                configObj.generalSettings.cdrPolicyPDF = '';
                //configObj.generalSettings.cdrPolicyURL = '';
                configObj.generalSettings.cdrUploadLogo = '';
                configObj.generalSettings.referenceCode = '';
                configObj.generalSettings.cdrUploadLogoDate = '';
                configObj.generalSettings.mtlsCertificatesId = '';
                configObj.generalSettings.consentExpiryPdfURL = '';
                if (configObj.generalSettings.enhancedRedirection) {
                    configObj.generalSettings.enhancedRedirection.clientId = '';
                    configObj.generalSettings.enhancedRedirection.clientSecret = '';
                    configObj.generalSettings.enhancedRedirection.jwtGenerationEndpoint = '';
                    configObj.generalSettings.enhancedRedirection.clientCredentialsEndpoint = '';
                }
                configObj.generalSettings.headerBackgroundImage = '';
                configObj.generalSettings.cancellationRedirectURL = '';
                configObj.generalSettings.consentRevocationPdfURL = '';
                configObj.generalSettings.consentConfirmationPdfURL = '';
                configObj.generalSettings.consentRecurringNotificationURL = '';
                configObj.generalSettings.headerBackgroundImageUploadDate = '';

                configObj.preConsent.cdrSupportingImageFileName = '';
                configObj.preConsent.cdrFeature1Image = '';
                configObj.preConsent.cdrFeature2Image = '';
                configObj.preConsent.cdrFeature3Image = '';
                configObj.preConsent.cdrFeature1ImageUploadDate = '';
                configObj.preConsent.cdrFeature2ImageUploadDate = '';
                configObj.preConsent.cdrFeature3ImageUploadDate = '';
                configObj.preConsent.pvpKeyVisualFileName = '';
                configObj.preConsent.pvpFeature1Image = '';
                configObj.preConsent.pvpFeature2Image = '';
                configObj.preConsent.pvpFeature3Image = '';
                configObj.preConsent.pvpFeature1ImageUploadDate = '';
                configObj.preConsent.pvpFeature2ImageUploadDate = '';
                configObj.preConsent.pvpFeature3ImageUploadDate = '';


                configObj.consent.feature1Image = '';
                configObj.consent.feature2Image = '';
                configObj.consent.feature3Image = '';
                configObj.consent.thirdPartiesEmail = '';


                configObj.insight.uploadLogo = '';

                (async() => {
                    const newConfigurations = props.configurations.slice();
                    //temporarily set new id for config and may be removed when real api implemented if id is wrong
                    configObj.id = newConfigurations.length;
                    await createNewConfiguration(configObj, props.accountId);
                    window.location.reload();
                })();
            } else {
                console.log("Import failed!");
            }
        }

    }

    const canViewAccountDetails = () => {
        if (isAdmin(props.currentUser?.role_id)) {
            return true;
        }

        if (isClientManager(props.currentUser?.role_id)) {
            if (props.currentUser?.account_id === props.accountId) {
                return true;
            }
        }

        if (isStandardUser(props.currentUser?.role_id)) {
            if (props.currentUser?.account_id === props.accountId) {
                return true;
            }
        }

        return false;
    }

    //eslint-disable-next-line
    const getAccountDetails = useCallback(props.adminObApi.getAccount, []);
    useEffect(() => {
            (async () => {
                if (props.accountId && props.currentUser && canViewAccountDetails()) {
                    try {
                        var accountId = isAdmin(props.currentUser.role_id) ? props.accountId : undefined;
                        const loadAccount = await getAccountDetails(accountId);
                        if (loadAccount) {
                            setAccount((userDetails)=>({
                                ...accountDetails,
                                id: loadAccount.id,
                                name: loadAccount.name,
                                reference_code: loadAccount.reference_code,
                                status: loadAccount.status,
                                api_key: loadAccount.api_key,
                                default_principal_id: loadAccount.default_principal_id,
                                account_type:loadAccount.account_type
                            }))
                        }
                    } catch (error) {
                        /*
                        // TODO Uncomment this code when fallback is removed
                        setAccount((userDetails)=>({
                            ...accountDetails,
                            apiHasReturnedAnError: true,
                        }))
                        navigate("/token-error");
                        */
                        adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                    }
                }
            })();
        },
        //eslint-disable-next-line
        [props.accountId, props.currentUser]
    )

    const navigate = useNavigate();

    //eslint-disable-next-line
    const createAccount = useCallback(props.adminObApi.createAccount, []);
    //eslint-disable-next-line
    const updateAccount = useCallback(props.adminObApi.updateAccount, []);
    const handleAccountUpdate = () => {
        setState({
            ...state,
            backgroundOperationInProgress: true,
        });

        setError('');

        //Save and send to server
        (async () => {
            try {
                if (props.accountId) {
                    const updatedAccountData = await updateAccount(accountDetails);
                    setAccount((accountDetails)=>({
                        ...accountDetails,
                        errorMessage: "",
                    }))
                    setState({
                        backgroundOperationInProgress: false,
                        //configurations: props.configurations,
                    })
                    setAccount({
                        ...accountDetails,
                        name: updatedAccountData.name,
                        reference_code: updatedAccountData.reference_code,
                    })
                    navigate(props.basePath + `/account/` + props.accountId);
                } else {
                    setState({
                        backgroundOperationInProgress: false,
                        //configurations: props.configurations,
                    })
                    const newAccount = await createAccount(accountDetails);
                    //navigate(props.basePath + `/account/` + newAccount.id);
                    navigate(props.basePath + `/account/` + newAccount.id, {
                        state: newAccount
                    })
                }
            } catch (error) {
                setState({
                    backgroundOperationInProgress: false,
                    //configurations: props.configurations,
                })

                let errorMessage = "Failed to " + (props.accountId ? 'Update' : 'create') + " Account";
                if((error as any).response?.data?.errors){
                    errorMessage += ' - ' + (error as any).response?.data?.errors;
                }
                setError(errorMessage);
            }
        })();
    }

    //This updates the state of the account details (specifically the new ID)
    //if we have been redirected after a route push on creating a new Account
    const location = useLocation();
    useEffect(() => {
        if (location.state && !accountDetails.id) {
            const account = location.state as Account;
            setAccount((accountDetails)=>({
                ...accountDetails,
                id: account.id as number,
            }));
        }
    }, [location.state, accountDetails.id])


    const handleGoBack = () => {
        //navigate(-1);
        navigate(`${props.basePath}/accounts-list`);
    };

    const theme = useTheme();
    const classes = {
        root: {
            textAlign: 'center'
        },
        largeIcon: {
            fontSize: "1.2em",
            padding: '0px !important',
            maxHeight: "none !important",
        },
        label: {
            color: theme.palette.secondary.main,
            fontSize: "1.2em",
            "&:hover": {
                textDecoration: "underline"
            }
        },
    };

    //eslint-disable-next-line
    const generateApiKey = useCallback(props.adminObApi.generateAccountApiKey, []);
    const handleGenerateApiKey = () => {
        (async () => {
            try {
                const newApiKey = await generateApiKey(accountDetails.id);
                setAccount({
                    ...accountDetails,
                    api_key: newApiKey.new_account_api_key,
                })
            } catch (error) {
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }

    const handleCreateConfigurationClick = () => {
        const targetLocation = isAdmin(props.currentUser?.role_id)
            ? `${props.basePath}/account/${props.accountId}/configuration`
            : `${props.basePath}/account/configuration`;
        navigate(targetLocation, {
            state: {
                isTrustedAdvisor: accountDetails.account_type === 2,
                accountType:accountDetails.account_type
            }
        });
    }

    return <>
        {(isAdmin(props.currentUser?.role_id)) ?
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
            </Grid>
        </Grid>
        : "" }

        <h2><strong>Account Management</strong></h2>

        {(state.backgroundOperationInProgress)
            ? <>Saving...</>
            : <div>
            
            {error && (
                <p className={"error-text"}>{error}</p>
            )}                

            <form className={"admin-form generalSettings-settings-form"} autoComplete={"off"}>
                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Name<LightTooltip title="Name of the Account being configured" placement="right-start"
                                                    arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        {(isAdmin(props.currentUser?.role_id)) ?
                            <TextField
                                required
                                variant="outlined"
                                name="name"
                                className="w-100"
                                placeholder="Name"
                                value={accountDetails.name?accountDetails.name:''}
                                onChange={(event) => {
                                    setAccount({
                                        ...accountDetails,
                                        name: event.target.value
                                    })
                                }}

                            />
                            : accountDetails.name?accountDetails.name: ''}
                    </Grid>
                </Grid>
                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Reference Code<LightTooltip title="Reference Code of the Account being configured" placement="right-start"
                                                            arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        {(isAdmin(props.currentUser?.role_id)) ?
                        <TextField
                            required
                            variant="outlined"
                            name="reference_code"
                            className="w-100"
                            placeholder="Reference Code"
                            value={accountDetails.reference_code?accountDetails.reference_code:''}
                            onChange={(event) => {
                                setAccount({
                                    ...accountDetails,
                                    reference_code: event.target.value
                                })
                            }}
                        />
                        : accountDetails.reference_code?accountDetails.reference_code:''}
                    </Grid>
                </Grid>
                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Status<LightTooltip title="Account Status" placement="right-start"
                                                    arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl variant="outlined" className="w-100">
                            {(isAdmin(props.currentUser?.role_id)) ?
                            <Select
                                id="status"
                                name="status"
                                value={accountDetails.status?accountDetails.status:'ACTIVE'}
                                onChange={(event: SelectChangeEvent<unknown>) => {
                                    setAccount({
                                        ...accountDetails,
                                        status: event.target.value as string
                                    })
                                }}
                            >
                                <MenuItem value={"ACTIVE"}>Active</MenuItem>
                                <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
                            </Select>
                            : accountDetails.status?accountDetails.status:'ACTIVE'}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Default Principal<LightTooltip title="Sets the default principal configuration to use if no principal is selected" placement="right-start"
                                                    arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl variant="outlined" className="w-100">
                            {(isAdmin(props.currentUser?.role_id)) ?
                            <Select
                                id="default_principal"
                                name="default_principal"
                                value={accountDetails.default_principal_id || 0}
                                onChange={(event: SelectChangeEvent<unknown>) => {
                                    setAccount({
                                        ...accountDetails,
                                        default_principal_id: event.target.value as number
                                    })
                                }}
                            >
                                <MenuItem key={"none"} value={0}>- Please select -</MenuItem>
                                {props.configurations.map((item, i) => (
                                    <MenuItem key={i} value={item.principal_id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                            : props.configurations.find(config => config.id === accountDetails.default_principal_id)?.name || 'NONE'}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>Account Type
                                <LightTooltip title="" placement="right-start" arrow>
                                    <InfoIcon color={"secondary"} />
                                </LightTooltip>
                            </label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            <FormControl variant="outlined" className="w-100">
                                {(isAdmin(props.currentUser?.role_id)) ?
                                    <Select
                                        id="account_type"
                                        name="account_type"
                                        value={accountDetails.account_type || ""}
                                        onChange={(event: SelectChangeEvent<unknown>) => {
                                            setAccount({
                                                ...accountDetails,
                                                account_type: event.target.value as number
                                            });
                                        }}
                                        disabled={accountTypeSelectionShouldDisable}
                                    >
                                        {accountTypes.map((type) => (
                                            <MenuItem key={type.id} value={type.id}>
                                                {type.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    : accountTypes.find(type => type.id === accountDetails.account_type)?.name || "N/A"}
                            </FormControl>
                        </Grid>
                    </Grid>

                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <Box mb={2}>
                            <h4>API Settings</h4>
                        </Box>

                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>API Key<LightTooltip title="Account's API Key" placement="right-start"
                                                            arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <TextField
                                    disabled
                                    required
                                    variant="outlined"
                                    name="api"
                                    className="w-100"
                                    value={accountDetails?.api_key || ''}
                                />
                                { (isAdmin(props.currentUser?.role_id)) ? <div><a href={"/#"} onClick={(e) => {e.preventDefault(); handleGenerateApiKey(); }}>Generate New API Key</a>&nbsp;</div> : ""}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {(isAdmin(props.currentUser?.role_id)) ?
                    <Box mt={3}>
                        <Button variant={"contained"} color={"secondary"} onClick={handleAccountUpdate}>Save</Button>
                    </Box>
                    : ""}
            </form>

            {(typeof props.accountId !== 'undefined' && props.accountId) ?
                <>
                    {props.isLoading ?
                        <Box mt={7}>
                            <LoadingSpinner loadingText={""} position={"inline-block"}/>
                        </Box>
                        :
                        (props.configurations.length > 0) ?
                            <>
                                <Box mt={7} mb={2}>
                                    <h4>Your configurations</h4>
                                </Box>
                                <Grid container spacing={4}>
                                    {props.configurations.map((item, i) => (
                                        <Grid item xs={12} sm={6} md={4} key={i}>
                                            <DashboardConfigurationCard
                                                key={i} {...item}
                                                basePath={props.basePath}
                                                accountId={props.accountId}
                                                currentUser={props.currentUser}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                            :
                            <Box mt={7}>
                                <h4>To get started, please create a new configuration.</h4>
                            </Box>
                    }
                </>
                : ""
            }

            {(typeof props.accountId !== 'undefined' && props.accountId && isAdminOrClientManager(props.currentUser?.role_id)) ?
                <Box mt={7}>
                    <ButtonBlock className={"text-align-left text-align-center-xs mt-0"}>
                        <Button variant={"contained"} color={"secondary"} onClick={handleCreateConfigurationClick}>Create configuration</Button>
                        <Button variant={"contained"} color={"secondary"} onClick={importConfigPopup}>Import configuration</Button>
                    </ButtonBlock>
                    <p className="mt-30">For help or support, please contact <a href="mailto:odssupport@illion.com.au">[placeholder]</a>.</p>
                </Box>
                : ""
            }
            </div>
        }

        <Popup
            heading={"Import configuration"}
            open={open}
            onClose={closePopup}
            textColor={'#000000'}
            buttons={<>
                <Button onClick={handleImport} variant={"contained"} color={"secondary"}>Import</Button>
                <Button onClick={closePopup} variant={"outlined"} color={"secondary"}>Cancel</Button>
            </>}
        >
            <p>Select the configuration file to be loaded into production:</p>
            <Box component="div" sx={classes.root}>
                <input
                    accept="application/json"
                    type="file"
                    onChange={handleUpload}
                    style={{display: 'none'}}
                    id="import-configuration"
                    ref={cdrFileUploadInput}
                />
                <label htmlFor="import-configuration">
                    <Typography component={"span"} sx={classes.label}>Upload</Typography>
                    <IconButton color="primary" aria-label="upload picture" component="span">
                        <InsertDriveFileOutlinedIcon sx={classes.largeIcon}/>
                    </IconButton>
                </label>
                <div>{state.uploadedConfigurationFileName}</div>
            </Box>
        </Popup>
    </>
}

export default ConfigurationDashboard;