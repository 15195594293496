import React, { useCallback, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";

import { Box, Container, Grid, Hidden } from "@mui/material";

import Footer from "../components/Footer";
import Header from "../components/Header";
import PrimaryBlock from "../components/PrimaryBlock";
import GeneralSettings from "../models/GeneralSettings";
import MultibankConsentsListData from "../models/MultibankConsentsListData";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import Insight from "../models/Insight";
import Consent from "../models/Consent";
import EditableRichText from "../admin/components/EditableRichText";
import { hasRichTextSpecifiedForField } from "../helpers/InputFieldValidationHelper";
import CustomerDetailsData from "../models/CustomerDetailsData";

interface MultibankSuccessProps {
    api: OpenBankingPlatformAPI;
    generalSettingsConfig?: GeneralSettings;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    insightConfig?: Insight;
    footerText?: string;
    consentConfig?: Consent;
    isTrustedAdviser?: boolean;
    accountType?: number;
}


const MultibankSuccess = (props: MultibankSuccessProps) => {
    const location = useLocation();
    const getRedirectionUrl = useCallback(async (type: any) => props.api.getRedirectionUrl(type), [props.api]);
    const [isInsight, setIsInsight] = useState(false);

    var sessionId = sessionStorage.getItem('sessionId');

    // TODO: Find a way to clear the session without using sessionStorage
    //sessionStorage.removeItem('sessionId');

    const customer = sessionStorage.getItem('customer');
    const customerData = customer ? JSON.parse(customer) as CustomerDetailsData : null;
    const customerId = customerData?.customer_id as string
    const [downloaded, setDownloaded] = useState(false);


    const getDownloadfiles = useCallback(props.api.postDownloadfiles, []);
    const handleDownloadFiles = (sessionId: string) => {
        //Send to server
        console.log('download raw data');
        if (sessionId !== null) {
            (async (sessionId) => {
                try {
                    const files = await getDownloadfiles(sessionId, customerId, props.generalSettingsConfig?.apiKey as string);
                    hasRun.current = true; // Prevents future executions
                    if (files) {
                        console.log(files.data)
                        const url = window.URL.createObjectURL(new Blob([files.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'data.zip');
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                    setDownloaded(true)
                } catch (error) {
                    console.log(error);
                }
            })(sessionId);
        }
    }

    const hasRun = useRef(false);

    useEffect(() => {
    
        var multiBankSelectionData = null;

        if (!hasRun.current && sessionId && props.generalSettingsConfig?.accountType === 3) {
            handleDownloadFiles(sessionId);
        }

    
        // @ts-ignore
        if (location.state && location.state.multiBankSelectionData) {
            // @ts-ignore
            multiBankSelectionData = location.state.multiBankSelectionData as MultibankConsentsListData;
            setIsInsight(multiBankSelectionData.is_insight);
        }

        if (typeof window !== "undefined" && window.top !== window.self && props.generalSettingsConfig?.callbackURL) {
            // Call back to the parent of the current window (if hosted in an iframe)
            // so that the principal can utilise it to update session state of the
            // consent lodged by the customer within their application
            window.parent.postMessage({
                message: "Success",
                value: multiBankSelectionData
            }, props.generalSettingsConfig.callbackURL)
        }

        // Then perform a redirection (if the redirectURL is provided in the Principal config)
        if (props.generalSettingsConfig?.redirectURL) {
            const redirectAfterSeconds = parseInt(props.generalSettingsConfig?.redirectAfterSeconds || "0") * 1000;
            if (props.generalSettingsConfig?.enhancedRedirection?.enableForSuccess) {
                (async () => {
                    try {
                        // Make API request to set the redirection URL
                        const redirectionUrl = await getRedirectionUrl('success');
                        if (redirectionUrl.url && redirectAfterSeconds > 0) {
                            setTimeout((successUrl) => {
                                window.location.href = successUrl;
                            }, redirectAfterSeconds, redirectionUrl.url);
                        }
                    } catch (error) {
                        if (redirectAfterSeconds > 0) {
                            setTimeout((pr: any) => {
                                (window as any).top.location.href = pr.generalSettingsConfig.redirectURL;
                            }, redirectAfterSeconds, props);
                        }
                    }
                })();
            } else {
                if (redirectAfterSeconds > 0) {
                    setTimeout((pr: any) => {
                        (window.top as any).location.href = pr.generalSettingsConfig.redirectURL;
                    }, redirectAfterSeconds, props);
                }
            }
        }
    },
        // eslint-disable-next-line
        [props.generalSettingsConfig, location, downloaded]
    );

    // Scroll to the top of the page once
    useEffect(() => {
        window.scrollTo(0, 0);
        window.parent.postMessage({ action: "OB_SCROLL_TO_TOP" }, "*");
    }, []);

    return <div className={"page-wrapper multi-bank-success"}>
        <div className={"page-top"}>
            <Header
                generalSettings={props.generalSettingsConfig}
                principalLogoUrl={props.principalLogoUrl}
                headerBgImageUrl={props.headerBgImageUrl}
                isTrustedAdviser={props.isTrustedAdviser}
            />
            <main>
                <PrimaryBlock>
                    <Container maxWidth="sm" className="p-0 success-confirmation">
                        <Grid container padding={2}>
                            <Grid item md={4} sm={12}>
                                <div className={"hero-image"} style={{paddingLeft: '3em'}}>
                                    <img src="/images/jumping-man.png" alt="" />
                                </div>
                            </Grid>
                            <Hidden smDown><Grid item md={1}></Grid></Hidden>
                            <Grid item md={7} sm={12} className="justify-content-center align-self-center">
                                <div>
                                    <h2 className="success-confirmation-font">{isInsight ? "Insight sharing successful" : "Success!"}</h2>
                                    <Box component={"span"} mb={3} className={"mt-10"}>
                                        {isInsight && props.generalSettingsConfig ?
                                            <>
                                                <p>Your insights have been shared with {props.insightConfig?.name}.</p>
                                                <p>A copy of your sharing arrangement has been sent to your email.</p>
                                                {props.generalSettingsConfig.redirectURL && <>
                                                    <p><a href={props.generalSettingsConfig.redirectURL}>Continue</a></p>
                                                </>}
                                            </>
                                            : <>
                                                {(props.consentConfig?.consentSuccessMessage && hasRichTextSpecifiedForField(props.consentConfig?.consentSuccessMessage)) ?
                                                    <EditableRichText
                                                        editMode={false}
                                                        value={props.consentConfig.consentSuccessMessage}
                                                    /> :
                                                    (props.isTrustedAdviser
                                                        ? <p>All done. You may close this window.</p>
                                                        : <>
                                                            <p>You have successfully shared the data from the institutions you have selected.</p>
                                                            <p>The secure sessions with the banks you have selected has been closed.</p>
                                                        </>
                                                    )
                                                }
                                            </>}
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </PrimaryBlock>
            </main>
        </div>

        {!props.isTrustedAdviser && <Footer generalSettingsConfig={props.generalSettingsConfig} isTrustedAdvisor={props.isTrustedAdviser} />}

    </div>
}

export default MultibankSuccess;