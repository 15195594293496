import Insight from "../models/Insight";
import Configuration from "../admin/models/Configuration";
import ConfigurationApi from '../admin/models/ConfigurationApi';
import Consent from "../models/Consent";
import ConsentListItemData from "../models/ConsentListItemData";
import CustomerDetailsData from "../models/CustomerDetailsData";
import DataCluster from "./models/DataCluster";
import DataClusterOptionSet from "../models/DataClusterOptionSet";
import DataHolderApi from "./models/DataHolderApi";
import PreConsent from "../models/PreConsent";
import PreConsentData from '../models/PreConsentData';
import DeveloperTools from '../models/DeveloperTools';


type Configurations = Array<Configuration>;

const mockDataClustersWithOptions: Array<DataClusterOptionSet> = [
    {
        clusterId: 0,
        clusterName: "Name and occupation",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Name", "Occupation"
        ],
        authorisationScopeId: "common:customer.basic:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: true,
        uuId: "",
        identifier: "data_cluster_1",
        isInsightEnabled: true,
        purposeInformationInsight: "These details will let us build your profile.",
    },
    {
        clusterId: 1,
        clusterName: "Contact details",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Phone", "Email address", "Mail address", "Residential address",
        ],
        authorisationScopeId: "common:customer.detail:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
        uuId: "",
        identifier: "data_cluster_2",
        isInsightEnabled: true,
        purposeInformationInsight: "These details will let us build your profile.",
    },
    {
        clusterId: 2,
        clusterName: "Name, occupation, contact details",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Name", "Occupation", "Phone", "Email address", "Mail address", "Residential address"
        ],
        authorisationScopeId: "common:customer.detail:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
        uuId: "",
        identifier: "data_cluster_3",
        isInsightEnabled: true,
        purposeInformationInsight: "These details will let us build your profile.",
    },
    {
        clusterId: 3,
        clusterName: "Organisation profile and contact details",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Agent name and role",
            "Organisation name",
            "Organisation numbers (ABN or ACN)",
            "Charity status",
            "Establishment date",
            "Industry",
            "Organisation type",
            "Country of registration",
            "Organisation address",
            "Mail address",
            "Phone number",
        ],
        authorisationScopeId: "common:customer.basic:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
        uuId: "",
        identifier: "data_cluster_4",
        isInsightEnabled: true,
        purposeInformationInsight: "These details will let us build your profile.",
    },
    {
        clusterId: 4,
        clusterName: "Organisation contact details",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Organisation address",
            "Mail address",
            "Phone number",
        ],
        authorisationScopeId: "common:customer.detail:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
        uuId: "",
        identifier: "data_cluster_5",
        isInsightEnabled: true,
        purposeInformationInsight: "These details will let us build your profile.",
    },
    {
        clusterId: 5,
        clusterName: "Organisation profile",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Agent name and role",
            "Organisation name",
            "Organisation numbers (ABN or ACN)",
            "Charity status",
            "Establishment date",
            "Industry",
            "Organisation type",
            "Country of registration",
        ],
        authorisationScopeId: "common:customer.detail:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
        uuId: "",
        identifier: "data_cluster_6",
        isInsightEnabled: true,
        purposeInformationInsight: "These details will let us build your profile.",
    },
    {
        clusterId: 6,
        clusterName: "Account name, type and balance",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Name of account",
            "Type of account",
            "Account balance",
        ],
        authorisationScopeId: "bank:accounts.basic:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
        uuId: "",
        identifier: "data_cluster_7",
        isInsightEnabled: true,
        purposeInformationInsight: "These details will let us build your profile.",
    },
    {
        clusterId: 7,
        clusterName: "Account numbers and features",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Account number",
            "Interest rates",
            "Fees",
            "Discounts",
            "Account terms",
            "Account mail address",
        ],
        authorisationScopeId: "bank:accounts.detail:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
        uuId: "",
        identifier: "data_cluster_8",
        isInsightEnabled: true,
        purposeInformationInsight: "These details will let us build your profile.",
    },
    {
        clusterId: 8,
        clusterName: "Account balance and details",
        purposeInformation: "This data allows us to identify how much money you are spending.",
        clusterPermissionNames: [
            "Name of account",
            "Type of account",
            "Account balance",
            "Account number",
            "Interest rates",
            "Fees",
            "Discounts",
            "Account terms",
            "Account mail address",
        ],
        authorisationScopeId: "bank:accounts.detail:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
        uuId: "",
        identifier: "data_cluster_9",
        isInsightEnabled: true,
        purposeInformationInsight: "These details will let us build your profile.",
    },
    {
        clusterId: 9,
        clusterName: "Transaction details",
        purposeInformation: "Transaction data allows us to identify how much money you have been spending.",
        clusterPermissionNames: [
            "Incoming and outgoing transactions",
            "Amounts",
            "Dates",
            "Descriptions of transactions",
            "Who you have sent money to and received money from; (e.g. their name)",
        ],
        authorisationScopeId: "bank:transactions:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
        uuId: "",
        identifier: "data_cluster_10",
        isInsightEnabled: true,
        purposeInformationInsight: "These details will let us build your profile.",
    },
    {
        clusterId: 10,
        clusterName: "Direct debits and scheduled payments",
        purposeInformation: "Transaction data allows us to identify how much money you have been spending.",
        clusterPermissionNames: [
            "Direct debits",
            "Scheduled payments",
        ],
        authorisationScopeId: "bank:regular_payments:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
        uuId: "",
        identifier: "data_cluster_11",
        isInsightEnabled: true,
        purposeInformationInsight: "These details will let us build your profile.",
    },
    {
        clusterId: 11,
        clusterName: "Saved payees",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Names and details of accounts you have saved (e.g. their BSB and Account Number, BPay CRN and Biller code, or NPP PayID)",
        ],
        authorisationScopeId: "bank:payees:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
        uuId: "",
        identifier: "data_cluster_12",
        isInsightEnabled: true,
        purposeInformationInsight: "These details will let us build your profile.",
    },
];

const mockConsent: Consent = {
    activeConsentDurationText: "To continue [reason] to [purpose] we need access to the following data for the next 12 months. We will continue to use data we’ve already collected, but only for what you consent to.",
    inclDataClusterPermissionText: true,
    dataClustersWithOptions: mockDataClustersWithOptions.slice(),    
	includeFrequencyOfDataCollectionText: true,
	frequencyOfDataCollectionTextMainHeading: "How often we’ll access your data",
	frequencyOfDataCollectionTextMainText: "We will do this every time you log into Money Simple. This will be ongoing for the next [Selected time period].",
    dataDeidentificationAndDeletionHeading: "What happens to your data after we’ve used it",
    includeDataDeidentificationStandardText: true,
    dataDeidentificationText: "",
    dataDeidentificationPopupLinkText: "",
    dataDeidentificationPopupContents: "",
    includeDataDeletionStandardText: true,
    dataDeletionText: "",
    managingYourDataHeading: "",
    managingYourDataText: "",
	includeReplaceAnExistingConsentText: true,
    sharingPeriodType: "default",
	featureHeading: "Learn about [Product Name]",
	numberOfFeatures: 1,
	feature1Image: "/images/share-your-data.png",
    feature1Text: "It’s a digital version of your card available 24/7 in the [Bank] App. The 3-digit CVC security number changes daily to ensure your details are kept safe.",
    dataWeNeedText: "Please select the data you would like to share.",
    consentSuccessMessage: "",
    supportingThirdParties: true,
}

const mockPreConsent: PreConsent = {
    inclPreConsentStage: true,
    inclKeyVisual: true,
    inclKeyText: true,
    keyTextHeading: "Track your spending with Money Simple",
    keyText: "Money Simple is brought to you in conjunction with illion’s Open Data Solutions. Open Data Solutions proprietary technology retrieves data from leading financial institutions and is independently tested and audited by external security experts.",
    ctaText: "Get started",
	pvpFeatureHeading: "Learn about [Product Name]",
	pvpNumberOfFeatures: 1,
	pvpFeature1Image: "/images/share-your-data.png",
	pvpFeature1ImageUploadDate: "10/02/2022",
    pvpFeature1Text: "It’s a digital version of your card available 24/7 in the [Bank] App. The 3-digit CVC security number changes daily to ensure your details are kept safe.",
    pvpKeyVisualFileName: "/images/hero-image-1.png",
	pvpKeyVisualFileUploadDate: "10/02/2022",
	dataHolderSelectionHeading: "Choose your bank or institution",
    includeNonCdrDataHoldersForSelection: true,
    cdrSupportingImageFileName: "/images/man-with-laptop-and-question-mark.png",
	cdrSupportingImageFileUploadDate: "10/02/2022",
    includeCdrSupportingImage: true,
	includeCdrKeyText: true,
	cdrKeyTextHeading: "Please provide your information",
	cdrKeyTextMainText: "To see where your money goes, and identify strategies for your personal financial situation, we need some information from you. We take your security very seriously. The credentials you provide are used solely to retrieve data on your behalf. Your credentials are stored by illion Open Data Solutions with bank level 256-bit encryption, secured by 2048-bit keys.",
	includeCdrVpInformation: true,
	cdrVpInformationHeading: "Share data with the Consumer Data Right",
	cdrVpInformationMainText: "You can connect us with your bank(s) via the Consumer Data Right. This option is quick, accurate and can save you time by providing us with correct information.",
    includeLinkToFurtherVerification: true,
    linkToFurtherVerification: "https://www.illion.com.au/about-us/cdr-policy",
	includeKeyFeaturesAndInformation: true,
	cdrFeatureHeading: "Learn about [Product Name]",
	cdrNumberOfFeatures: 1,
	cdrFeature1Image: "/images/share-your-data.png",
    cdrFeature1Text: "It’s a digital version of your card available 24/7 in your [Bank] App. The 3-digit CVC security number changes daily to ensure your details are kept safe.",
}

const mockDataHolder: DataHolderApi = {
    id: 1,
    identifier: "1",
    name: "Test",
    logo_uri: "/images/data-holder-logos/test-logo.png",
    is_cdr: true,
    is_top_bank: true
}

const mockConsentDetail: ConsentListItemData = {
    additional_uses_of_data: [],
    id: 1,
    uuid: '89f10975-e5ff-48c8-a329-076fedfa3b66',
    is_cdr: true,
    personal_details: "stev jobs",
    principal: "ANZ",
    data_holder_brand_id: 123,
    institution_id: '12345',
    institution_name: "Test Bank",
    institution_industry: "Banking",
    data_holder_brand_is_cdr: true,
    data_holder_brand_is_top_bank: true,
    confirmation_pdf: "confirmation.pdf",
    withdraw_pdf: "withdraw.pdf",
    logo_uri: "/images/data-holder-logos/test-logo.png",
    status: "Active",
    effective_at: "2021-03-13 12:11:09",
    effective_at_styled: "13 March 2021",
    withdrawn_at: "2021-03-13 12:11:09",
    sharing_period: "1 month",
    sharing_start_at: "2021-03-13 12:11:09",
    initial_sharing_start_at: "2021-03-13 12:11:09",
    sharing_end_at: "2021-03-13 12:11:09",
    created_at_styled: "13 March 2021",
    is_insight: true,
    sharing_start_at_styled: "13 March 2021",
    withdrawn_at_styled: "13 March 2021",
    delete_my_data_after_used: true,
    message: {
        type: "success",
        content: "You have successfully consented to sharing your ANZ data with us. You can see a summary of this sharing arrangement in here.",
        links: [{
            icon: "download",
            text: "Download confirmation PDF",
            url: "/sample-url"
        }]
    }
}

const mockInsight: Insight = {
    name: "insight",
    uploadLogo: "",
    businessDescription: "", 
    purposeInsightWasRequested: "",
    mainHeading: "Insights we’ll share",
    subHeading: "Select the insights you would like us to share with [Non-accredited Third Party Name]. We'll do this to support [purpose].",
    activeConsentDurationText: "We need to collect the information below to generate your insight.",
    dataHandlingMainHeading: "Data hadling",
    dataHandlingSubHeading: "", //The data you share with illion is subject to CDR protections.",
    // dataInsightsWithOptions: mockDataInsightsWithOptions.slice(),
    dataWeNeedText: "Please select the data you would like to share. The more you share, the more accurately you can track your spending. We will only ask for what we need.",

    // 1
    verifyIdentityName: "Verify your identity",
    incVerifyIdentity: true,
    verifyIdentityIsSelected: true,
    verifyIdentityIsNotValid: false,
    incIdentityDescription: "",
    //We used your data to generate the insights:\nName is [Name].\nAddress is [Address].\nHow was this insight generated?\nTo generate this insight we used 'Name, occupation, contact details' that we collected from [Data holder name].",

    // 2
    verifyBalanceName: "Verify your account balance",
    incVerifyBalance: true,
    verifyBalanceIsSelected: true,
    verifyBalanceIsNotValid: false,
    incBalanceDescription: "",
    //We used your data to generate the insights:\nAccount number is [BSB and account number].\nAccount balance is [amount].\nAverage account balance is [amount].\nDays in negative is [#] days.\nHow was this insight generated?\nTo generate this insight we used 'Account balance and details' that we collected from [Data holder name].",

    // 3
    verifyIncomeName: "Verify your income",
    incVerifyIncome: true,
    verifyIncomeIsSelected: true,
    verifyIncomeIsNotValid: false,
    incIncomeDescription: "",
    //We used your data to generate the insights:\nMonthly average income is [amount].\nBased on the last [#] months, total income is [amount].\nHow was this insight generated?\nTo generate this insight we used 'Transaction details' that we collected from [Data holder name].",

    // 4
    verifyBankAccountName: "Verify your bank account",
    incVerifyBankAccount: true,
    verifyBankAccountIsSelected: true,
    verifyBankAccountIsNotValid: false,
    incBankAccountDescription: "",
    //We used your data to generate the insights:\nAccount number is [BSB and account number].\nHow was this insight generated?\nTo generate this insight we used 'Account balance and details' that we collected from [Data holder name]. ",

    includeFrequencyOfDataCollectionText: true,
	frequencyOfDataCollectionTextMainHeading: "How often we’ll access your data",
	frequencyOfDataCollectionTextMainText: "We will do this every time you log into Money Simple. This will be ongoing for the next [Selected time period].",

    sharingPeriodType: "default",

    includeDataDeletionStandardText: true,
	includeReplaceAnExistingConsentText: true,
}

const mockDeveloperTools: DeveloperTools = {
    incConsumerConsentSessionTimedOut: false,
    incCannotLoadConsumerConsentFlow: false,
    incUnexpectedErrorEncounteredDuringTheAuth: false,
    incUnknownError: false,
    incCdrServiceUnavailable: false,
    incOdsServiceUnavailable: false,
}

const mockConfigurations: Array<Configuration> = [
    {
        id: 0,
        name: "Configuration #1",
        description: "Description of demo configuration.",
        generalSettings: {
            accountType: 1,
            principalId: 123,
            principalName: "Money Simple",
            accreditationNumber: "123456789",
            adrName: "ADR name",
            referenceCode: "ABCD",
            isActive: true,
            multibankEnabled: false,
            isDeliverEstatement:true,
            cdrPolicyPDF: "CDR policy pdf file holder",
            headingFontFamily: "Nunito Sans",
            bodyTextFontFamily: "Nunito Sans",
            footerFontFamily: "Nunito Sans",
            textColor: "#404040",
            primaryColor: "#8C2980",
            secondaryColor: "#FF9843",
            backgroundColor: "#FFFFFF",
            headerBackgroundType: "none",
            headerBackgroundImage: "",
            headerBackgroundImageUploadDate: "",
            headerBackgroundColor: "#FFFFFF",
            footerBackgroundType: "none",
            footerBackgroundColor: "#FFFFFF",
            footerTextColor: "#777777",
            showIconInAccreditationBadge: false,
            accreditationUploadIcon: '',
            accreditationUploadIconDate: '',
            includeCancelButtonAndPopUp: true,
            cancelModalPopUpHeading: "Cancel Process?",
            cancelModalPopUpMainText: "By not sharing your bank data, we will not be able to automatically collect this information. However, you can still provide this information manually when setting up Money Simple. Are you sure you would like to cancel this process?",
            sendConsentExpiryEmail: false,
            incInsightStage: false,
            apiKey: 'abcd',
        },
        preConsent: { ...mockPreConsent },
        consent: { ...mockConsent },
        insight: { ...mockInsight },
        developerTools: { ...mockDeveloperTools },
        dashboard: {
            includeConnectInstitutionButton: true
        },
        principal_id: 1,
        isConsentUpdateFromDashboard: false,
        accountType:1
    },
    {
        id: 1,
        name: "Configuration #2",
        description: "Description of demo configuration.",
        generalSettings: {
            accountType: 1,
            principalId: 123,
            principalName: "Money Simple",
            accreditationNumber: "ABCDE12345",
            adrName: "ADR Name",
            referenceCode: "ABCD",
            isActive: true,
            multibankEnabled: false,
            isDeliverEstatement:false,
            cdrPolicyPDF: "CDR policy pdf file holder",
            headingFontFamily: "Nunito Sans",
            bodyTextFontFamily: "Nunito Sans",
            footerFontFamily: "Nunito Sans",
            textColor: "#FFFFFF",
            primaryColor: "#8C2980",
            secondaryColor: "#FF9843",
            backgroundColor: "#121212",
            headerBackgroundType: "none",
            headerBackgroundImage: "",
            headerBackgroundImageUploadDate: "",
            showIconInAccreditationBadge: false,
            accreditationUploadIcon: '',
            accreditationUploadIconDate: '',
            includeCancelButtonAndPopUp: true,
            cancelModalPopUpHeading: "Cancel Process?",
            cancelModalPopUpMainText: "By not sharing your bank data, we will not be able to automatically collect this information. However, you can still provide this information manually when setting up Money Simple. Are you sure you would like to cancel this process?",
            sendConsentExpiryEmail: false,
            incInsightStage: false,
            apiKey: 'abcd',
        },
        preConsent: {
            ...mockPreConsent,
            inclPreConsentStage: false,
        },
        consent: mockConsent,
        insight: { ...mockInsight },
        developerTools: { ...mockDeveloperTools },
        dashboard: {
            includeConnectInstitutionButton: true
        },
        principal_id: 2,
        isConsentUpdateFromDashboard: false,
        accountType:1
    }
];

//Removed the unwanted interface from mock api as it may cause unwanted errors
export default class OpenBankingPlatformMockApi
{
    constructor() {
        this.getDataClusters = this.getDataClusters.bind(this);
        this.getDataHolders = this.getDataHolders.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.getPrincipalConfigurations = this.getPrincipalConfigurations.bind(this);
        this.getConfiguration = this.getConfiguration.bind(this);
        this.getCurrentConfiguration = this.getCurrentConfiguration.bind(this);
        this.getPrincipalAdminConfigurationList = this.getPrincipalAdminConfigurationList.bind(this);

        this.newConfigurationId = 2;
        this.configurations = mockConfigurations.slice();
        this.dataHolder = mockDataHolder;
    }

    private newConfigurationId: number;
    private configurations: Array<Configuration>;
    private dataHolder: DataHolderApi;

    private async doSimulatedRequest<T>(responseData: T): Promise<T>
    {
        const timeout = (ms: number) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        await timeout(1000);

        return responseData;
    }

    public async getDataClusters(): Promise<Array<DataCluster>> {
        return this.doSimulatedRequest<Array<DataCluster>>([]);
    }

    public async getDataHolders(): Promise<Array<DataHolderApi>> {
        return this.doSimulatedRequest<Array<DataHolderApi>>([]);
    }

    public async getPreConsentData(): Promise<PreConsentData> {
        return this.doSimulatedRequest<PreConsentData>({});
    }

    public async savePreConsentData(): Promise<any> {
        return this.doSimulatedRequest<PreConsentData>({});
    }

    public async saveCustomerDetailsData(): Promise<any> {
        return this.doSimulatedRequest<CustomerDetailsData>({});
    }

    public async getDashboardConsents(): Promise<Array<ConsentListItemData>> {
        return this.doSimulatedRequest<Array<ConsentListItemData>>([]);
    }

    public async getSelectedDataClustersForConsent(): Promise<Array<DataCluster>> {
        return this.doSimulatedRequest<Array<DataCluster>>([]);
    }

    public async getDataHolderById(): Promise<any> {
        return this.doSimulatedRequest<DataHolderApi>(mockDataHolder);
    }

    public async getConsentById(): Promise<ConsentListItemData> {
        return this.doSimulatedRequest<ConsentListItemData>(mockConsentDetail);
    }

    private getConfigurationById(configurationId: number) {
        return this.configurations.find((configuration) => (configuration.id === configurationId));
    }

    private getConfigurationIndexById(configurationId: number) {
        return this.configurations.findIndex((configuration) => (configuration.id === configurationId));
    }

    // TODO this should be replaced with call to getDataClusters and adding options outside the call
    public async getDataClustersWithOptions(): Promise<Array<DataClusterOptionSet>>
    {
        return this.doSimulatedRequest<Array<DataClusterOptionSet>>(
            mockDataClustersWithOptions.slice(),
        );
    }

    public async getConfiguration(configurationId: number): Promise<Configuration>
    {
        const configuration = this.getConfigurationById(configurationId);

        if (!configuration) {
            throw new Error("Requested configuration does not exist");
        }

        return this.doSimulatedRequest<Configuration>(configuration);
    }

    public async getCurrentConfiguration(): Promise<Configuration>
    {
        const configuration = this.getConfigurationById(0);

        if (!configuration) {
            throw new Error("Requested configuration does not exist");
        }

        return this.doSimulatedRequest<Configuration>(configuration);
    }

    public async getPrincipalConfigurations(principalId: number): Promise<Array<Configuration>>
    {
        if (principalId === 0) {
            return this.doSimulatedRequest<Configurations>(this.configurations.slice());
        } else {
            return this.doSimulatedRequest<Configurations>([
                this.configurations[0],
            ]);
        }
    }

    public async getPrincipalAdminConfigurationList(): Promise<Array<ConfigurationApi>>
    {
        return this.doSimulatedRequest<Array<ConfigurationApi>>([]);
    }

    public async updateConfiguration(principalId: number, newConfiguration: Configuration): Promise<Configuration>
    {
        const configurationIndex = this.getConfigurationIndexById(newConfiguration.id);

        if (!configurationIndex) {
            throw new Error("Requested configuration does not exist");
        }

        this.configurations[configurationIndex] = newConfiguration;
        return this.doSimulatedRequest<Configuration>(this.configurations[configurationIndex]);
    }
}
