import axios from "axios";

import Account from "../admin/models/Account";
import BillingLog from "../admin/models/BillingLog";
import CdrToDdcBankMapping from "../admin/models/CdrToDdcBankMapping";
import Configuration from "../admin/models/Configuration";
import ConfigurationApi from "../admin/models/ConfigurationApi";
import Principal from "../admin/models/Principal";
import PrincipalInvoicingDetails from "../admin/models/PrincipalInvoicingDetails";
import UpdateUserData from "../admin/models/UpdateUserData";
import UpdateBrokerData from "../admin/models/UpdateBrokerData";
import User from "../admin/models/User";
import Broker from "../admin/models/Broker";
import UserLogin from "../admin/models/UserLogin";
import BankFeedsInitialisationData from "../models/BankFeedsInitialisationData";
import ConsentListItemData from "../models/ConsentListItemData";
import CurrentUser from "../models/CurrentUser";
import CustomerDetailsData from "../models/CustomerDetailsData";
import CustomerInputCheck from "../models/CustomerInputCheck";
import MultibankConsentsListData from "../models/MultibankConsentsListData";
import MultibankSelectionData from "../models/MultibankSelectionData";
import NewMultiBankSelectionData from "../models/NewMultiBankSelectionData";
import PostConsentData from "../models/PostConsentData";
import PreConsentData from "../models/PreConsentData";
import ValidateTokenResponse from "../models/ValidateTokenResponse";
import DataCluster from "../openbankingplatform/models/DataCluster";
import DataHolderApi from "./models/DataHolderApi";
import OpenBankingPlatformAPIInterface from "./OpenBankingPlatformAPIInterface";
import OpenBankingPlatformMockApi from "./OpenBankingPlatformMockApi";
import PrincipalBillingConfiguration from "../admin/models/PrincipalBillingConfiguration";
import BillingConfigurationApi from "../admin/models/BillingConfigurationApi";
import MtlsCertificates from "../admin/models/MtlsCertificates";
import CdrClientsCertificates from "../admin/models/CdrClientsCertificates";


axios.defaults.withXSRFToken = true;
axios.defaults.withCredentials = true;

export default class OpenBankingPlatformAPI implements OpenBankingPlatformAPIInterface {
    constructor(private apiUrl: URL, private accessToken: string) {
        this.accessToken = accessToken;
        this.getDataClusters = this.getDataClusters.bind(this);
        this.getDataHolders = this.getDataHolders.bind(this);
        this.getConfigurationMockData = this.getConfigurationMockData.bind(this);
        this.createConfiguration = this.createConfiguration.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.getPrincipalConfigurations = this.getPrincipalConfigurations.bind(this);
        this.getConfiguration = this.getConfiguration.bind(this);
        this.getCurrentConfiguration = this.getCurrentConfiguration.bind(this);
        this.getPrincipalAdminConfigurationListByAccount = this.getPrincipalAdminConfigurationListByAccount.bind(this);
        this.createNewConfiguration = this.createNewConfiguration.bind(this);
        this.createReferralCode = this.createReferralCode.bind(this);
        this.updateExistingConfiguration = this.updateExistingConfiguration.bind(this);
        this.validateToken = this.validateToken.bind(this);
        this.getTrustedAdviserConsentSession = this.getTrustedAdviserConsentSession.bind(this);
        this.getConsentSession = this.getConsentSession.bind(this);
        this.getUser = this.getUser.bind(this);
        this.getPreConsentData = this.getPreConsentData.bind(this);
        this.savePreConsentData = this.savePreConsentData.bind(this);
        this.getInitialisePAR = this.getInitialisePAR.bind(this);
        this.savePostConsentToken = this.savePostConsentToken.bind(this);
        this.getCustomerDetails = this.getCustomerDetails.bind(this);
        this.saveCustomerDetailsData = this.saveCustomerDetailsData.bind(this);
        this.getInitialiseAuth = this.getInitialiseAuth.bind(this);
        this.getDashboardConsents = this.getDashboardConsents.bind(this);
        this.changeSelectedPrincipal = this.changeSelectedPrincipal.bind(this);
        this.getSelectedDataClustersForConsent = this.getSelectedDataClustersForConsent.bind(this);
        this.getSelectedInsightsForConsent = this.getSelectedInsightsForConsent.bind(this);
        this.getDataHolderById = this.getDataHolderById.bind(this);
        this.getConsentById = this.getConsentById.bind(this);
        this.updateConsent = this.updateConsent.bind(this);
        this.withdrawConsentById = this.withdrawConsentById.bind(this);
        this.validateAdminToken = this.validateAdminToken.bind(this);
        this.doAdminLogin = this.doAdminLogin.bind(this);
        this.doAdminMfaLogin = this.doAdminMfaLogin.bind(this);
        this.doAdminLogout = this.doAdminLogout.bind(this);
        this.doAdminForgotPassword = this.doAdminForgotPassword.bind(this);
        this.doAdminValidateTokenForResetPassword = this.doAdminValidateTokenForResetPassword.bind(this);
        this.doAdminResetPassword = this.doAdminResetPassword.bind(this);
        this.doUserSignUp = this.doUserSignUp.bind(this);
        this.doUserUpdate = this.doUserUpdate.bind(this);
        this.getUsersList = this.getUsersList.bind(this);
        this.getUserById = this.getUserById.bind(this);
        this.getBrokersList = this.getBrokersList.bind(this);
        this.getBrokerById = this.getBrokerById.bind(this);
        this.doBrokerSignUp = this.doBrokerSignUp.bind(this);
        this.doBrokerUpdate = this.doBrokerUpdate.bind(this);
        this.doBrokersImport = this.doBrokersImport.bind(this);
        this.doBrokersExport = this.doBrokersExport.bind(this);
        this.getIsBrokerValid = this.getIsBrokerValid.bind(this);
        this.isNewBrokerCodeUnique = this.isNewBrokerCodeUnique.bind(this);
        this.getAccount = this.getAccount.bind(this);
        this.getAccountsList = this.getAccountsList.bind(this);
        this.getTrustedAdviserAccountsList = this.getTrustedAdviserAccountsList.bind(this);
        this.getPrincipalsList = this.getPrincipalsList.bind(this);
        this.getPrincipalInvoicingDetails = this.getPrincipalInvoicingDetails.bind(this);
        this.getConsentsList = this.getConsentsList.bind(this);
        this.saveUploadFile = this.saveUploadFile.bind(this);
        this.getPrincipalsByAccountId = this.getPrincipalsByAccountId.bind(this);
        this.createAccount = this.createAccount.bind(this);
        this.updateAccount = this.updateAccount.bind(this);
        this.saveMultibankSession = this.saveMultibankSession.bind(this);
        this.getMultibankSession = this.getMultibankSession.bind(this);
        this.validateUser = this.validateUser.bind(this);
        this.requestBankfeedsApiUrl = this.requestBankfeedsApiUrl.bind(this);
        this.saveNonCdrArrangement = this.saveNonCdrArrangement.bind(this);
        this.processMultibankSession = this.processMultibankSession.bind(this);
        this.deletePreviousData = this.deletePreviousData.bind(this);
        this.getDownloadPdf = this.getDownloadPdf.bind(this);
        this.getConsentHistoryDownloadPdf = this.getConsentHistoryDownloadPdf.bind(this);
        this.getFile = this.getFile.bind(this);
        this.getErrorLogsList = this.getErrorLogsList.bind(this);
        this.getCdrToDdcBankMappingsList = this.getCdrToDdcBankMappingsList.bind(this);
        this.getCdrToDdcBankMapping = this.getCdrToDdcBankMapping.bind(this);
        this.createCdrToDdcBankMapping = this.createCdrToDdcBankMapping.bind(this);
        this.updateCdrToDdcBankMapping = this.updateCdrToDdcBankMapping.bind(this);
        this.getSystemSettings = this.getSystemSettings.bind(this);
        this.updateSystemSettings = this.updateSystemSettings.bind(this);
        this.getCsrfToken = this.getCsrfToken.bind(this);
        this.doVerificationTokenRequest = this.doVerificationTokenRequest.bind(this);
        this.generatePrincipalApiKey = this.generatePrincipalApiKey.bind(this);
        this.generateAccountApiKey = this.generateAccountApiKey.bind(this);
        this.getConfigurationByPrincipal = this.getConfigurationByPrincipal.bind(this);
        this.getBillingConfiguration = this.getBillingConfiguration.bind(this);
        this.updateBillingConfiguration = this.updateBillingConfiguration.bind(this);
        this.getBillingLogsByPrincipal = this.getBillingLogsByPrincipal.bind(this);
        this.getRedirectionUrl = this.getRedirectionUrl.bind(this);
        this.getNonCdrFlowInitialisationUrl = this.getNonCdrFlowInitialisationUrl.bind(this);
        this.getConsentHistoryById = this.getConsentHistoryById.bind(this);
        this.finishConsentSession = this.finishConsentSession.bind(this);
        this.listMtlsCertificates = this.listMtlsCertificates.bind(this);
        this.getMtlsCertificates = this.getMtlsCertificates.bind(this);
        this.uploadMtlsCertificates = this.uploadMtlsCertificates.bind(this);
        this.downloadMtlsFile = this.downloadMtlsFile.bind(this);
        this.downloadCdrConsumerReport = this.downloadCdrConsumerReport.bind(this);
        this.listCdrClientsCertificates = this.listCdrClientsCertificates.bind(this);
        this.getCdrClientsCertificates = this.getCdrClientsCertificates.bind(this);
        this.uploadCdrClientsCertificates = this.uploadCdrClientsCertificates.bind(this);
        this.downloadCdrClientsFile = this.downloadCdrClientsFile.bind(this);
        this.doRegistration = this.doRegistration.bind(this);
        this.validatePostConsentSession = this.validatePostConsentSession.bind(this);
        this.getAsicCreditLicenseeOrRepresentative = this.getAsicCreditLicenseeOrRepresentative.bind(this);
        this.sendTrustedAdviserRecoverDashboardEmail = this.sendTrustedAdviserRecoverDashboardEmail.bind(this);
        this.downloadTADataDeliveryFile = this.downloadTADataDeliveryFile.bind(this);
        this.validateDDToken = this.validateDDToken.bind(this);
        this.postDownloadfiles = this.postDownloadfiles.bind(this)
    }

    private async doGetBankfeedsApiRequest<T>(path: string, params?: object): Promise<T> {
        const bfUrl = process.env.REACT_APP_BANKFEEDS_API_ENDPOINT_URL;
        if (!bfUrl) {
            throw Error("REACT_APP_BANKFEEDS_API_ENDPOINT_URL is not defined in the configuration");
        }

        const url = (new URL(bfUrl)).toString() + `v1${path}`;
        const timeout = parseInt(process.env.REACT_APP_API_TIMEOUT ?? "15000");
        const request = axios
            .get<T>(url, {
                method: 'get',
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "X-API-KEY": process.env.REACT_APP_BANKFEEDS_API_KEY as string,
                    'X-OUTPUT-VERSION': '20170401',
                },
                params: params,
                timeout: timeout,
            });
        return request.then((response: any) => {
            return response.data;
        });
    }

    private async doCsrfCookieRequest<T>(path: string): Promise<T> {
        const url = process.env.REACT_APP_API_DOMAIN + `/${path}`;
        const request = axios
            .get<T>(url, {
                method: 'get',
            });
        return request.then((response: any) => {
            return response.data.data;
        });
    }

    private async doPostBankfeedsApiRequest<T>(path: string, params?: any): Promise<T> {
        const bfUrl = process.env.REACT_APP_BANKFEEDS_API_ENDPOINT_URL;
        if (!bfUrl) {
            throw Error("REACT_APP_BANKFEEDS_API_ENDPOINT_URL is not defined in the configuration");
        }

        const url = (new URL(bfUrl)).toString() + `v1${path}`;
        const request = axios
            .post<T>(url,
                params,
                {
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "X-API-KEY": process.env.REACT_APP_BANKFEEDS_API_KEY as string,
                        'X-OUTPUT-VERSION': '20170401'
                    },
                }
            );

        return request.then((response: any) => {
            return response.data;
        });
    }

    private async doGetRequest<T>(path: string, params?: object, requiresAccessToken = false): Promise<T> {
        const url = this.apiUrl.toString() + `/${path}`;
        const timeout = parseInt(process.env.REACT_APP_API_TIMEOUT ?? "15000");

        const headers: any = {
            Accept: `application/json`,
        };

        if (requiresAccessToken) {
            headers.Authorization = `Bearer ${this.accessToken}`;
        }

        const request = axios
            .get<T>(url, {
                method: 'get',
                headers: headers,
                params: params,
                timeout: timeout,
            });
        return request.then((response: any) => {
            if(response.data.token) {
                this.accessToken = response.data.token;
                sessionStorage.setItem('tokenString', response.data.token);
            }
            return response.data.data;
        });
    }

    private async doPostRequestDownload<T>(path: string, params?: object, retrieveFromCloudFront: boolean = false): Promise<T> {
        var url = this.apiUrl.toString() + `/${path}`;
        if (retrieveFromCloudFront) {
            url = new URL(process.env.REACT_APP_CLOUDFRONT_DISTRIBUTION_API_URL ?? '').toString() + `/${path}`;
        }
        const timeout = parseInt(process.env.REACT_APP_API_TIMEOUT ?? "15000");
        const request = axios
            .post<T>(url, params,{
                headers: {
                    //Authorization: `Bearer ${this.accessToken}`,
                    'Accept': 'application/octet-stream',
                    'Content-Type': 'application/json',
                    ...(retrieveFromCloudFront && { 'Cache-Control': 'max-age=604800' }),
                },
                timeout: timeout,
                responseType: 'blob',
                withCredentials: true
            });
        return request.then((response: any) => {
            return response;
        });
    }

    private async doAppPostRequestDownload<T>(path: string, apiKey: string, params?: object): Promise<T> {
        var url = this.apiUrl.toString() + `/${path}`;
        const timeout = parseInt(process.env.REACT_APP_API_TIMEOUT ?? "120000");
        const request = axios
            .post<T>(url, params,{
                headers: {
                    'X-API-Key': apiKey,
                    'Content-Type': 'application/json'
                },
                timeout: timeout,
                responseType: 'blob',
            });
        return request.then((response: any) => {
            return response;
        });
    }

    private async doGetRequestDownload<T>(path: string, params?: object, retrieveFromCloudFront: boolean = false): Promise<T> {
        var url = this.apiUrl.toString() + `/${path}`;
        if (retrieveFromCloudFront) {
            url = new URL(process.env.REACT_APP_CLOUDFRONT_DISTRIBUTION_API_URL ?? '').toString() + `/${path}`;
        }
        const timeout = parseInt(process.env.REACT_APP_API_TIMEOUT ?? "15000");
        const request = axios
            .get<T>(url, {
                method: 'get',
                headers: {
                    //Authorization: `Bearer ${this.accessToken}`,
                    Accept: `application/json`,
                    ...(retrieveFromCloudFront && { 'Cache-Control': 'max-age=604800' }),
                },
                params: params,
                timeout: timeout,
                responseType: 'blob',
            });
        return request.then((response: any) => {
            return response.data;
        });
    }

    private async doGetRequestDownloadWithContentHeaders<T>(path: string, params?: object): Promise<T> {
        const url = this.apiUrl.toString() + `/${path}`;
        const timeout = parseInt(process.env.REACT_APP_API_TIMEOUT ?? "15000");
        const request = axios
            .get<T>(url, {
                method: 'get',
                headers: {
                    //Authorization: `Bearer ${this.accessToken}`,
                    Accept: `application/json`,
                },
                params: params,
                timeout: timeout,
                responseType: 'blob',
            });
        return request.then((response: any) => {
            return response;
        });
    }

    private async doPostRequest<T>(path: string, params?: any, requiresVerificationToken: boolean = false): Promise<T> {
        const url = this.apiUrl.toString() + `/${path}`;

        if (requiresVerificationToken) {
            await this.doVerificationTokenRequest();
        }

        const request = axios
            .post<T>(url,
                params,
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                        Accept: `application/json`,
                    },
                }
            );

        return request.then((response: any) => {
            return response.data.data;
        });
    }

    private async doPostMultiDataRequest<T>(path: string, params?: any): Promise<T> {
        const url = this.apiUrl.toString() + `/${path}`;
        await this.doVerificationTokenRequest();
        const request = axios
            .post<T>(url,
                params,
                {
                    headers: {
                        //Authorization: `Bearer ${this.accessToken}`,
                        "Content-Type": `multipart/form-data; boundary=` + Math.random().toString().substr(2)
                    },
                }
            );

        return request.then((response: any) => {
            return response.data.data;
        });
    }

    public async doVerificationTokenRequest<T>(): Promise<T> {
        const path = `auth/get-token`;
        return this.doGetRequest<any>(path, {}, true);
    }

    public async getDataClusters(): Promise<Array<DataCluster>> {
        const path = `data-clusters-list`;
        return this.doGetRequest<Array<DataCluster>>(path, {}, true);
    }

    public async getDataHolderById(data_holder_brand_id: number): Promise<DataHolderApi> {
        const path = `get-data-holder`;
        return this.doPostRequest<DataHolderApi>(path, {
            data_holder_brand_id: data_holder_brand_id
        });
    }

    public async getConsentById(consent_id: string): Promise<ConsentListItemData> {
        const path = 'get-consent';
        return this.doPostRequest<ConsentListItemData>(path, {
            consent_id: consent_id
        });
    }

    public async updateConsent(consent_id: string, delete_my_data_after_used: boolean): Promise<any> {
        const path = 'update-consent';
        return this.doPostRequest<any>(path, {
            consent_id: consent_id,
            delete_my_data_after_used: delete_my_data_after_used
        }, true);
    }

    public async getCurrentConfiguration(): Promise<Configuration> {
        const path = `get-config`;
        return this.doGetRequest<Configuration>(path, {}, true);
    }

    public async getCsrfToken(): Promise<any> {
        const path = `sanctum/csrf-cookie`;
        return this.doCsrfCookieRequest<any>(path);
    }

    public async validateToken(): Promise<ValidateTokenResponse> {
        const path = `validate-token`;
        return this.doGetRequest<ValidateTokenResponse>(path, {}, true);
    }

    public async validateDDToken(): Promise<ValidateTokenResponse> {
        const path = `data-delivery/validate-token`;

        return this.doGetRequest<ValidateTokenResponse>(path, {}, true);
    }

    public async getTrustedAdviserConsentSession(brokerCode?: string): Promise<Configuration> {
        const path = `get-session` + (brokerCode !== '' ? `/${brokerCode}` : '');
        return this.doGetRequest<Configuration>(path, {});
    }

    public async getConsentSession(brokerCode?: string): Promise<Configuration> {
        const path = `get-session` + (brokerCode !== '' ? `/${brokerCode}` : '');
        return this.doGetRequest<Configuration>(path, {}, true);
    }

    public async getUser(): Promise<any> {
        const path = `user`;
        return this.doGetRequest<any>(path, {});
    }

    public async getCustomerDetails(): Promise<CustomerInputCheck> {
        const path = 'get-customer-details';
        return this.doGetRequest<CustomerInputCheck>(path, {}, true);
    }

    public async getPreConsentData(data_holder_brand_id: string): Promise<PreConsentData> {
        const path = `get-pre-consent/${data_holder_brand_id}`;
        return this.doGetRequest<PreConsentData>(path, {}, true);    
    }

    public async getInitialisePAR(data_holder_brand_id: string): Promise<any> {
        const path = `initialise-par/${data_holder_brand_id}`;
        return this.doGetRequest<any>(path, {}, true);
    }

    public async getInitialiseAuth(data_holder_brand_id: string, params?: object): Promise<any> {
        const path = `initialise-auth/${data_holder_brand_id}`;
        return this.doGetRequest<any>(path, params);
    }

    public async savePreConsentData(pre_consent_data: PreConsentData): Promise<any> {
        const path = `save-pre-consent`;
        const additional_uses_of_data = pre_consent_data?.additional_uses_of_data || [];
        const delete_my_data_after_used = pre_consent_data?.delete_my_data_after_used === 1 ? 'true' : 'false';
        const uuid = pre_consent_data?.uuid ?? 'null';
        const consent_exists = pre_consent_data.consent_exists ? 'true' : 'false';
        return this.doPostRequest<PreConsentData>(
            path,
            {
                selected_data_clusters: pre_consent_data?.selected_data_clusters?.join(' '),
                selected_data_clusters_purpose_information: pre_consent_data?.selected_data_clusters_purpose_information,
                data_holder_brand_identifier: pre_consent_data?.data_holder_brand_identifier,
                sharing_period: pre_consent_data?.sharing_period,
                additional_uses_of_data: additional_uses_of_data,
                delete_my_data_after_used: delete_my_data_after_used,
                delete_previous_data_on_amend_consent: pre_consent_data.delete_previous_data_on_amend_consent,
                uuid: uuid,
                consent_exists: consent_exists,
                is_insight: pre_consent_data?.is_insight ? '1' : '0',
                selected_insights: pre_consent_data?.selected_insights?.join(' ')
            }, true
        );
    }

    public async requestBankfeedsApiUrl(bank_feeds_request_data: any): Promise<BankFeedsInitialisationData> {
        const path = `/customer/initialise?institution_type=all&show_back=false`;
        let response = this.doPostBankfeedsApiRequest<BankFeedsInitialisationData>(path, {
            customer_name: bank_feeds_request_data.customer_name,
            link_expiry: bank_feeds_request_data.link_expiry,
            reference: bank_feeds_request_data.reference,
            request_export_selection: bank_feeds_request_data.request_export_selection,
            performOutputSubmission: bank_feeds_request_data.performOutputSubmission,
            with_transactions: bank_feeds_request_data.with_transactions,
            request_centrelink: bank_feeds_request_data.request_centrelink,
            generate_raw_file: bank_feeds_request_data.generate_raw_file,
            institution: bank_feeds_request_data.institution,
            cdr_customer_id: bank_feeds_request_data.cdr_customer_id,
            cdr_principal_id: bank_feeds_request_data.cdr_principal_id,
            cdr_is_multibank_session: bank_feeds_request_data.cdr_is_multibank_session
        });
        return response;
    }

    public async savePostConsentToken(postConsentData: any): Promise<PostConsentData> {
        const path = `save-post-consent-token`;
        return this.doPostRequest<PostConsentData>(
            path,
            {
                code: postConsentData.code,
                id_token: postConsentData.id_token,
                state: postConsentData.state,
                access_token: postConsentData.access_token,
                data_holder_brand_identifier: postConsentData.data_holder_brand_identifier,
                is_multibank_session: postConsentData.isMultibankSession,
                error: postConsentData.error,
                error_description: postConsentData.error_description,
                error_uri: postConsentData.error_uri,
            }
        );
    }

    public async saveCustomerDetailsData(customer_details_data: CustomerDetailsData): Promise<CustomerInputCheck> {
        const path = 'save-customer-details';
        return this.doPostRequest<CustomerInputCheck>(
            path,
            {
                given_name: customer_details_data.given_name,
                surname: customer_details_data.surname,
                email: customer_details_data.email
            }, true);
    }

    //Admin side api calls
    public async getPrincipalAdminConfigurationListByAccount(account_id?: number): Promise<Array<ConfigurationApi>> {
        const path = `get-config-list`;
        /*
        return this.doPostRequest<Array<ConfigurationApi>>(path, {
            account_id: account_id
        });*/
        return this.doPostRequest<Array<ConfigurationApi>>(path,
            account_id ? { account_id: account_id } : {});
    }

    //To save config
    public async createNewConfiguration(config: Configuration, account_id?: number): Promise<Configuration> {
        const path = `save-config`;
        return this.doPostRequest<Configuration>(path,
            account_id ?
                {
                    account_id: account_id,
                    config: JSON.stringify(config)
                } :
                {
                    config: JSON.stringify(config)
                }, true);
    }

    public async createReferralCode(principal_id?: number): Promise<Configuration> {
        const path = `save-referral-code`;
        return this.doPostRequest<Configuration>(path,{            
            principal_id: principal_id
        }, true);
    }

    //To Update Config
    public async updateExistingConfiguration(config: Configuration, principal_id?: number): Promise<Configuration> {
        const path = `save-config`;
        return this.doPostRequest<Configuration>(
            path,
            {
                config: JSON.stringify(config),
                principal_id: principal_id,
            }, true
        );
    }

    public async getDashboardConsents(pageId: number, searchKeyword: string, searchCategory: string): Promise<any> {
        const path = `consents-list`;
        return this.doGetRequest<Array<ConsentListItemData>>(path, {
            page: pageId,
            search: searchKeyword,
            category: searchCategory
        });
    }

    public async changeSelectedPrincipal(principalId: number): Promise<Configuration> {
        const path = `change-selected-principal/${principalId}`;
        return this.doGetRequest<Configuration>(path, {}, true);
    }

    public async getDataHolders(): Promise<Array<DataHolderApi>> {
        const path = `data-holders-list`;
        return this.doGetRequest<Array<DataHolderApi>>(path, {}, true);
    }

    public async getSelectedDataClustersForConsent(consent_id: number): Promise<Array<DataCluster>> {
        const path = `consent-selected-data-clusters-list`;
        return this.doPostRequest<Array<DataCluster>>(path, {
            consent_id: consent_id
        });
    }

    public async getSelectedInsightsForConsent(consent_id: number): Promise<Array<string>> {
        const path = `consent-selected-insights-list`;
        return this.doPostRequest<Array<string>>(path, {
            consent_id: consent_id
        });
    }

    public async withdrawConsentById(consent_id: number): Promise<ConsentListItemData> {
        const path = `revoke-consent`;
        return this.doPostRequest<ConsentListItemData>(path, {
            consent_id: consent_id
        }, true);
    }

    //To validate the admin token
    public async validateAdminToken(): Promise<any> {
        const path = `auth/validate-token`;
        return this.doGetRequest<any>(path, {});
    }

    //Validate the current user
    public async validateUser(): Promise<CurrentUser> {
        const path = `auth/user`;
        return this.doGetRequest<CurrentUser>(path, {});
    }

    //To Login to the admin portal
    public async doAdminLogin(loginCredentials: UserLogin): Promise<any> {
        const path = `auth/login`;
        return this.doPostRequest<any>(path, {
            email: loginCredentials.email,
            password: loginCredentials.password,
            remember_me: 0, //@ToDo : Set this value if required later
        });
    }

    public async doAdminMfaLogin(mfaCode: string): Promise<any> {
        const path = `auth/mfaLogin`;
        return this.doPostRequest<any>(path, {
            mfaCode: mfaCode,
        });
    }

    //To validate the admin token
    public async doAdminLogout(): Promise<any> {
        const path = `auth/logout`;
        return this.doGetRequest<any>(path, {});
    }

    public async doAdminForgotPassword(email: string): Promise<any> {
        const path = `forgotPassword`;
        return this.doPostRequest<any>(path, {
            email: email,
        });
    }

    public async doAdminValidateTokenForResetPassword(token: string, email: string): Promise<any> {
        const path = `resetPassword/${token}`;
        return this.doGetRequest<any>(path, {
            email: email,
        });
    }

    public async doAdminResetPassword(token: string, email: string, password: string, password_confirmation: string): Promise<any> {
        const path = `resetPassword`;
        return this.doPostRequest<any>(path, {
            token: token,
            email: email,
            password: password,
            password_confirmation: password_confirmation
        });
    }

    public async doUserSignUp(userCredentials: UpdateUserData): Promise<UpdateUserData> {
        const path = `auth/user-signup`;
        return this.doPostRequest<UpdateUserData>(path, {
            name: userCredentials.name,
            email: userCredentials.email,
            password: userCredentials.password,
            password_confirmation: userCredentials.password_confirmation,
            current_password: userCredentials.current_password,
            role_id: userCredentials.role_id,
            is_active: userCredentials.is_active,
            ...(userCredentials.account_id && { account_id: userCredentials.account_id }) // account_id is conditionally included in parameters if provided
        }, true);
    }

    public async doUserUpdate(userCredentials: UpdateUserData): Promise<UpdateUserData> {
        const path = `auth/user-update`;
        return this.doPostRequest<UpdateUserData>(path, {
            id: userCredentials.id,
            name: userCredentials.name,
            email: userCredentials.email,
            password: userCredentials.password,
            password_confirmation: userCredentials.password_confirmation,
            current_password: userCredentials.current_password,
            role_id: userCredentials.role_id,
            is_active: userCredentials.is_active,
            ...(userCredentials.account_id && { account_id: userCredentials.account_id }) // account_id is conditionally included in parameters if provided
        }, true);
    }

    public async getUsersList(pageId: number, searchKeyword: string, section: string, accountId?: number): Promise<any> {
        const path = `auth/users-list`;
        return this.doPostRequest<Array<User>>(path, {
            page: pageId,
            search: searchKeyword,
            section: section,
            account_id: accountId,
        });
    }

    public async getUserById(userId: number): Promise<User> {
        const path = 'auth/get-user';
        return this.doPostRequest<User>(path, {
            user_id: userId
        });
    }

    public async getBrokersList(pageId: number, searchKeyword: string, accountId?: number): Promise<any> {
        const path = `auth/brokers-list`;
        return this.doPostRequest<Array<User>>(path, {
            page: pageId,
            search: searchKeyword,
            account_id: accountId,
        });
    }

    public async getBrokerById(brokerId: number): Promise<Broker> {
        const path = 'auth/get-broker';
        return this.doPostRequest<Broker>(path, {
            broker_id: brokerId
        });
    }

    public async doBrokerSignUp(brokerCredentials: UpdateBrokerData): Promise<UpdateBrokerData> {
        const path = `auth/broker-signup`;
        return this.doPostRequest<UpdateBrokerData>(path, {
            first_name: brokerCredentials.first_name,
            last_name: brokerCredentials.last_name,
            email: brokerCredentials.email,
            account_id: brokerCredentials.account_id,
            principal_id: brokerCredentials.principal_id,
            mobile_number: brokerCredentials.mobile_number,
            type: brokerCredentials.type,
            business_phone_number: brokerCredentials.business_phone_number,
            address: brokerCredentials.address,
            registration_number: brokerCredentials.registration_number,
            sub_type: brokerCredentials.sub_type,
            acl_credit_license_number: brokerCredentials.acl_credit_license_number,
            acl_credit_license_holder_name: brokerCredentials.acl_credit_license_holder_name,
            acl_abn: brokerCredentials.acl_abn,
            acr_credit_representative_number: brokerCredentials.acr_credit_representative_number,
            acr_credit_license_number: brokerCredentials.acr_credit_license_number,
            acr_credit_representative_name: brokerCredentials.acr_credit_representative_name,
            acr_abn: brokerCredentials.acr_abn,
            code: brokerCredentials.code
        }, true);
    }

    public async doBrokerUpdate(brokerCredentials: UpdateBrokerData): Promise<UpdateBrokerData> {
        const path = `auth/broker-update`;
        return this.doPostRequest<UpdateBrokerData>(path, {
            id: brokerCredentials.id,
            first_name: brokerCredentials.first_name,
            last_name: brokerCredentials.last_name,
            email: brokerCredentials.email,
            account_id: brokerCredentials.account_id,
            principal_id: brokerCredentials.principal_id,
            mobile_number: brokerCredentials.mobile_number,
            type: brokerCredentials.type,
            business_phone_number: brokerCredentials.business_phone_number,
            address: brokerCredentials.address,
            registration_number: brokerCredentials.registration_number,
            sub_type: brokerCredentials.sub_type,
            acl_credit_license_number: brokerCredentials.acl_credit_license_number,
            acl_credit_license_holder_name: brokerCredentials.acl_credit_license_holder_name,
            acl_abn: brokerCredentials.acl_abn,
            acr_credit_representative_number: brokerCredentials.acr_credit_representative_number,
            acr_credit_license_number: brokerCredentials.acr_credit_license_number,
            acr_credit_representative_name: brokerCredentials.acr_credit_representative_name,
            acr_abn: brokerCredentials.acr_abn,
            code: brokerCredentials.code
        }, true);
    }

    public async doBrokersImport(fileData: any, account_id?: string, principal_id?: string): Promise<any> {
        const path = 'auth/brokers-import-csv';
        return this.doPostRequest(path, {
            data: fileData,
            account_id: account_id,
            principal_id: principal_id
        }, true);
    }

    public async doBrokersExport(account_id?: string, principal_id?: string): Promise<any> {

        const path = 'auth/brokers-export-csv';
        // console.log('do export for account_id', account_id);
        return this.doGetRequestDownload<any>(path, {
            account_id: account_id,
            principal_id: principal_id
        });
    }

    public async getIsBrokerValid(mortgageBrokerType: string, number: string): Promise<Broker> {
        const path = 'auth/is-valid-credit-license-or-representative-number';
        return this.doPostRequest<Broker>(path, {
            type: mortgageBrokerType,
            number: number
        });
    }

    public async getAsicCreditLicenseeOrRepresentative(mortgageBrokerType: string, number: string): Promise<any> {
        const path = 'auth/get-asic-credit-licensee-representative-by-number';
        return this.doPostRequest<any>(path, {
            type: mortgageBrokerType,
            number: number
        });
    }

    public async isNewBrokerCodeUnique(code?: string, broker_id?: number): Promise<Broker> {
        const path = 'auth/is-new-broker-code-unique';
        return this.doPostRequest<Broker>(path, {
            code: code,
            broker_id: broker_id
        });
    }

    public async getAccount(accountId?: number): Promise<Account> {
        const path = 'get-account';
        return this.doPostRequest<Account>(path, accountId ? { account_id: accountId } : {});
    }

    public async getAccountTypes(): Promise<Account> {
        const path = 'get-account-types';
        return this.doGetRequest<any>(path, {});
    }

    public async getAccountsList(): Promise<Array<Account>> {
        const path = `accounts-list`;
        return this.doGetRequest<Array<Account>>(path, {});
    }

    public async getTrustedAdviserAccountsList(): Promise<Array<Account>> {
        const path = `accounts-list`;
        return this.doGetRequest<Array<Account>>(path, {
            for_trusted_advisers: 1
        });
    }

    public async getConsentsList(pageId: number, searchKeyword: string, section: string): Promise<any> {
        const path = `consents-list`;
        return this.doGetRequest<any>(path, {
            page: pageId,
            search: searchKeyword,
            section: section
        });
    }

    public async saveUploadFile(fileData: FormData): Promise<Account> {
        const path = `upload-file`;
        return this.doPostMultiDataRequest<Account>(path, fileData);
    }

    public async getPrincipalsByAccountId(accountId: number): Promise<Array<Principal>> {
        const path = 'principals-list-by-account';
        return this.doPostRequest<Array<Principal>>(path, {
            account_id: accountId
        });
    }

    public async createAccount(account: Account): Promise<Account> {
        const path = `save-account`;
        return this.doPostRequest<Account>(path, {
            name: account.name,
            reference_code: account.reference_code,
            status: account.status,
            account_type:account.account_type
        }, true);
    }

    public async updateAccount(account: Account): Promise<Account> {
        const path = `save-account`;
        return this.doPostRequest<Account>(path, {
            account_id: account.id,
            name: account.name,
            reference_code: account.reference_code,
            status: account.status,
            default_principal_id: account.default_principal_id,
            account_type:account.account_type
        }, true);
    }

    public async saveMultibankSession(data_holder_brand_identifer: string, multibank_session_id: string, consent_uuid?: string): Promise<NewMultiBankSelectionData> {
        const path = 'save-multibank-session';
        return this.doPostRequest<NewMultiBankSelectionData>(path, {
            data_holder_brand_identifer: data_holder_brand_identifer,
            multibank_session_id: multibank_session_id,
            consent_uuid: consent_uuid
        });
    }

    public async getMultibankSession(session_id: string): Promise<Array<MultibankSelectionData>> {
        const path = 'get-multibank-session';
        return this.doPostRequest<Array<MultibankSelectionData>>(path, {
            session_id: session_id,
        });
    }

    public async saveNonCdrArrangement(data_holder_brand_identifer: number): Promise<any> {
        const path = 'save-non-cdr-arrangement';
        return this.doPostRequest<any>(
            path,
            {
                data_holder_brand_identifier: data_holder_brand_identifer,
            }
        );
    }

    public async processMultibankSession(session_id: string): Promise<MultibankConsentsListData> {
        const path = 'process-multibank-session';
        return this.doPostRequest<MultibankConsentsListData>(path, {
            session_id: session_id,
        });
    }

    public async deletePreviousData(pre_consent_data: PreConsentData): Promise<any> {
        const path = 'delete-previous-data';
        return this.doPostRequest<any>(
            path, {
            data_holder_brand_identifier: pre_consent_data?.data_holder_brand_identifier,
        }
        );
    }

    public async getDownloadPdf(consent_uuid: string, type: number): Promise<any> {
        const path = `get-pdf/${consent_uuid}/${type}`;
        return this.doGetRequestDownload<any>(path, {});
    }

    public async getConsentHistoryDownloadPdf(consent_uuid: string, type: number): Promise<any> {
        const path = `get-history-pdf/${consent_uuid}/${type}`;
        return this.doGetRequestDownload<any>(path, {});
    }

    public async getFile(fileName: string, retrieveFromCloudFront: boolean = false): Promise<any> {
        //console.log('test get file commit');
        if (!fileName || fileName === '') return null;

        // if file name ends with .png, then request will be blocked by CORS error;
        // so this fix replace dot with underscore;
        fileName=fileName.replace(/\.(jpg|jpeg|png|gif|bmp|tiff|svg|webp)$/i, '_$1');
        const path = `filename${fileName}`;
        return this.doGetRequestDownload<any>(path, {}, retrieveFromCloudFront);
    }

    public async postDownloadfiles(sessionId: string, customerId: string, apiKey: string): Promise<any> {
        const path = `getrawbankingdata`;
        return this.doAppPostRequestDownload<any>(path, apiKey,{
            customer_id: customerId,
            reference: sessionId,
            async: false,
            export_format: 'json',
            schema_version: '20230101',
            include_files: true
        });
    }

    public async getErrorLogsList(pageId: number, searchKeyword?: string, section?: string, dateFrom?: string, dateTo?: string): Promise<any> {
        const path = `error-logs`;
        return this.doGetRequest<Array<Account>>(path, {
            page: pageId,
            dateFrom: dateFrom,
            dateTo: dateTo,
            search: searchKeyword,
            section: section
        });
    }

    public async getCdrToDdcBankMappingsList(): Promise<Array<CdrToDdcBankMapping>> {
        const path = `cdr-ddc-mappings-list`;
        return this.doGetRequest<Array<CdrToDdcBankMapping>>(path, {});
    }

    public async getCdrToDdcBankMapping(mapping_id: number): Promise<CdrToDdcBankMapping> {
        const path = `get-cdr-ddc-mapping`;
        return this.doPostRequest<CdrToDdcBankMapping>(path, {
            mapping_id: mapping_id
        });
    }

    public async createCdrToDdcBankMapping(mapping: CdrToDdcBankMapping): Promise<CdrToDdcBankMapping> {
        const path = `update-cdr-ddc-mappings`;
        return this.doPostRequest<CdrToDdcBankMapping>(path, {
            cdr_data_holder_id: mapping.cdr_dh_id,
            ddc_data_holder_id: mapping.ddc_dh_id,
            status: mapping.status
        }, true);
    }

    public async updateCdrToDdcBankMapping(mapping: CdrToDdcBankMapping): Promise<CdrToDdcBankMapping> {
        const path = `update-cdr-ddc-mappings`;
        return this.doPostRequest<CdrToDdcBankMapping>(path, {
            mapping_id: mapping.id,
            cdr_data_holder_id: mapping.cdr_dh_id,
            ddc_data_holder_id: mapping.ddc_dh_id,
            status: mapping.status
        }, true);
    }

    public async getSystemSettings(): Promise<any> {
        const path = `get-system-settings`;
        return this.doGetRequest<any>(path, {});
    }

    public async updateSystemSettings(sectionName: string, value: string): Promise<any> {
        const path = `update-system-settings/${sectionName}`;
        return this.doPostRequest<any>(path, {
            value: value
        }, true);
    }

    public async generatePrincipalApiKey(principal_id?: number): Promise<any> {
        const path = `regenerate-principal-api-key`;
        return this.doPostRequest<any>(path, {
            principal_id: principal_id
        }, true);
    }

    public async generateAccountApiKey(account_id?: number): Promise<any> {
        const path = `regenerate-account-api-key`;
        return this.doPostRequest<any>(path, account_id ? { account_id: account_id } : {}, true);
    }

    public async getPrincipalsList(pageId: number, searchKeyword: string, section: string): Promise<Array<Principal>> {
        const path = `principals-list`;
        return this.doPostRequest<Array<Principal>>(path, {
            page: pageId,
            search: searchKeyword,
            section: section,
        });
    }

    public async getPrincipalInvoicingDetails(principal_id: number, from_date: string, to_date: string): Promise<PrincipalInvoicingDetails> {
        const path = `principal-invoicing-details`;
        return this.doPostRequest<PrincipalInvoicingDetails>(path, {
            principal_id: principal_id,
            from_date: from_date,
            to_date: to_date
        });
    }

    public async getConfigurationByPrincipal(principal_id: number): Promise<ConfigurationApi> {
        const path = `get-principal-config`;
        return this.doPostRequest<ConfigurationApi>(path, {
            principal_id: principal_id
        })
    }

    public async getBillingConfiguration(principal_id: number): Promise<BillingConfigurationApi> {
        const path = `get-billing-config`;
        return this.doPostRequest<BillingConfigurationApi>(path, {
            principal_id: principal_id
        })
    }

    //To Update Config
    public async updateBillingConfiguration(config: PrincipalBillingConfiguration, principal_id?: number): Promise<PrincipalBillingConfiguration> {
        const path = `save-billing-config`;
        return this.doPostRequest<PrincipalBillingConfiguration>(
            path,
            {
                config: JSON.stringify(config),
                principal_id: principal_id,
            }, true
        );
    }

    public async getBillingLogsByPrincipal(pageId: number, principal_id: number, from_date: string, to_date: string): Promise<Array<BillingLog>> {
        const path = `get-principal-billing-logs`;
        return this.doPostRequest<Array<BillingLog>>(path, {
            page: pageId,
            principal_id: principal_id,
            from_date: from_date,
            to_date: to_date
        });
    }

    public async getRedirectionUrl(
        type: string = 'cancel',
        error = undefined
    ): Promise<any> {
        const path = `get-redirection-url/${type}`;
        return this.doPostRequest<any>(path, error);
    }

    public async getNonCdrFlowInitialisationUrl(data_holder_brand_identifer: string, is_multibank?: boolean): Promise<any> {
        const path = 'get-non-cdr-flow-initialisation-url';
        return this.doPostRequest<any>(path, {
            data_holder_brand_identifer: data_holder_brand_identifer,
            is_multibank: is_multibank
        });
    }

    public async getConsentHistoryById(consent_id: string): Promise<any> {
        const path = 'get-consent-history';
        return this.doPostRequest<any>(path, {
            consent_id: consent_id
        });
    }

    public async finishConsentSession(): Promise<any> {
        const path = 'finish-consent-session';
        return this.doPostRequest<any>(path);
    }

    public async listMtlsCertificates(): Promise<Array<MtlsCertificates>> {
        const path = `list-mtls-certs`;
        return this.doGetRequest<Array<MtlsCertificates>>(path, {});
    }

    public async getMtlsCertificates(mtls_certificates_id: number): Promise<MtlsCertificates> {
        const path = `get-mtls-certs`;
        return this.doPostRequest<MtlsCertificates>(path, {
            mtls_certificate_id: mtls_certificates_id
        });
    }

    public async uploadMtlsCertificates(formData: FormData): Promise<MtlsCertificates> {
        const path = `upload-mtls-certs`;
        return this.doPostMultiDataRequest<MtlsCertificates>(path, formData);
    }

    public async downloadMtlsFile(mtls_certificates_id: number, type: string): Promise<any> {
        const path = `mtls/download/${mtls_certificates_id}/${type}`;
        return this.doGetRequestDownloadWithContentHeaders<any>(path, {});
    }

    public async downloadCdrConsumerReport(customerId?: string, customerGivenName?: string, customerSurname?: string, customerEmail?: string, dateFrom?: string, dateTo?: string, reportType?: string, exportFormat?: string): Promise<any> {
        const path = `cdr-consumer-report/download/${reportType}`;
        // console.log('do export for account_id', account_id);
        return this.doGetRequestDownload<any>(path, {
            customer_id: customerId,
            customer_given_name: customerGivenName,
            customer_surname: customerSurname,
            customer_email: customerEmail,
            date_from: dateFrom,
            date_to: dateTo,
            export_format: exportFormat
        });
    }

    public async listCdrClientsCertificates(): Promise<Array<CdrClientsCertificates>> {
        const path = `list-cdr-clients-certs`;
        return this.doGetRequest<Array<CdrClientsCertificates>>(path, {});
    }

    public async getCdrClientsCertificates(cdr_clients_certificates_id: number): Promise<CdrClientsCertificates> {
        const path = `get-cdr-clients-certs`;
        return this.doPostRequest<CdrClientsCertificates>(path, {
            cdr_clients_certificate_id: cdr_clients_certificates_id
        });
    }

    public async uploadCdrClientsCertificates(formData: FormData): Promise<CdrClientsCertificates> {
        const path = `upload-cdr-clients-certs`;
        return this.doPostMultiDataRequest<CdrClientsCertificates>(path, formData);
    }

    public async downloadCdrClientsFile(cdr_clients_certificates_id: number, type: string): Promise<any> {
        const path = `cdr-clients/download/${cdr_clients_certificates_id}/${type}`;
        return this.doGetRequestDownloadWithContentHeaders<any>(path, {});
    }

    public async doRegistration(cdr_clients_certificates_id: number): Promise<any> {
        const path = `cdr-client-register`;
        return this.doPostRequest<any>(path, {
            cdr_clients_certificate_id: cdr_clients_certificates_id
        });
    }

    public async validatePostConsentSession(): Promise<any> {
        const path = `validate-session`;
        return this.doGetRequest<any>(path, {}, true);
    }

    public async sendTrustedAdviserRecoverDashboardEmail(email: string, brokerCode: string): Promise<any> {
        const path = `recover-dashboard-link/${brokerCode}`;
        return this.doPostRequest<any>(path, {
            email: email,
        });
    }


    public async downloadTADataDeliveryFile(email: string, password: string, code: string, attachments: Array<any>): Promise<any> {
        const path = `download`;
        return this.doPostRequestDownload<any>(path, {
            email: email,
            password: password,
            code: code,
            attachments: attachments
        });
    }


    public async getConfigurationMockData(account_type: number): Promise<Configuration> {
        const path = `get-configuration-mock-data`;
        return this.doPostRequest<Configuration>(path, {account_type}, true);
    }

    public async createConfiguration(principalId: number, name: string, description: string, account_type: number): Promise<Configuration> {
        const mockConfig = await this.getConfigurationMockData(account_type);
        const config = { ...mockConfig, principal_id: principalId, name, description, generalSettings: { ...mockConfig.generalSettings, principalId }};
        return config;
    }

    // TODO using mock api to stub not yet implemented functions. This should be removed in production.
    private mockApi = new OpenBankingPlatformMockApi();

    public async getConfiguration(configurationId: number): Promise<Configuration> {
        return this.mockApi.getConfiguration(configurationId);
    }

    public async getPrincipalConfigurations(principalId: number): Promise<Array<Configuration>> {
        return this.mockApi.getPrincipalConfigurations(principalId);
    }

    public async updateConfiguration(principalId: number, newConfiguration: Configuration): Promise<Configuration> {
        return this.mockApi.updateConfiguration(principalId, newConfiguration);
    }
}